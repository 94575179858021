import React from 'react';
import { Header, TextNote, InputFormContainer } from './StyledParts';

const PendingAccountNotice = () => {
  return (
    <>
      <Header>Thanks for signing up for Plantlife!</Header>
      <InputFormContainer>
        <TextNote>
          Due to new AGLC regulations as of March 8th, 2022 in order to view our online menu and be
          able to order for pickup or delivery, you must verify your ID to confirm you are over 18
          years old.
          <br />
          <br />A Plantlife team member will be in touch shortly to verify your age virtually or you
          can visit your nearest location to verify your age through a piece of government issued
          ID.
        </TextNote>
      </InputFormContainer>
    </>
  );
};

export default PendingAccountNotice;
