import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { ChakraProvider } from '@chakra-ui/react';
import AgeGate from './components/AgeGate';

function App() {
  return (
    <ChakraProvider>
      <CookiesProvider>
        <AgeGate />
      </CookiesProvider>
    </ChakraProvider>
  );
}

export default App;
