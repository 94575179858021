import React from "react";
import {
    Header,
    Text,
    TextNote,
    FormButton,
    Input,
    InputFormContainer,
    LinkButton,
    Spinner
} from "./StyledParts";

const LoginForm = (props) => {
    const {
        setContentType,
        handleFormInput,
        isRequesting,
        formHandler,
        formContent,
        response
    } = props;

    const handleClick = (event) => {
        event.preventDefault();
        if (!isRequesting) {
            setContentType("login");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("submit form!");
        formHandler("forgot");
    };

    if (response?.passwordReset === true) {
        return (
            <>
                <Header>Forgot password form submitted.</Header>
                <InputFormContainer>
                    <TextNote>
                        Please check your email for a new password, and return
                        to login.
                    </TextNote>
                </InputFormContainer>
                <Text>
                    <LinkButton onClick={handleClick} disabled={isRequesting}>
                        Return to login
                    </LinkButton>
                </Text>
            </>
        );
    }

    return (
        <>
            <Header>
                Are you already Age Verified? Login here to start shopping!
            </Header>
            {isRequesting && <Spinner />}
            {!isRequesting && (
                <InputFormContainer>
                    <form onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            name="forgot_email"
                            placeholder="Enter email"
                            value={formContent.forgot_email || ""}
                            onChange={handleFormInput}
                        />
                        <FormButton type="submit">Reset My Password</FormButton>
                    </form>
                </InputFormContainer>
            )}
            <Text>
                <LinkButton onClick={handleClick} disabled={isRequesting}>
                    Cancel
                </LinkButton>
            </Text>
        </>
    );
};

export default LoginForm;
