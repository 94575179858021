import React from 'react';
import styled from '@emotion/styled';
import GridLoader from 'react-spinners/GridLoader';

export const contentBgColor = '#3B822D';
export const errorBgColor = '#E80049';
export const textColor = '#ddd';
export const labelColor = '#444';
export const headerColor = '#B5FF6B';
export const linkColor = headerColor; // "#7BE52C";
export const lightBorderColor = '#aaa';

export const FullScreenBlocker = styled.div`
  position: fixed;
  inset: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  max-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  background-color: ${contentBgColor};
  background-size: cover;
`;

export const ErrorContainer = styled.div`
  background-color: ${errorBgColor};
  padding: 12px;
  text-align: center;
  border-radius: 8px;
  & h1 {
    color: #fff;
    font-size: 1.15em;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-style: italic;
  }
  & p {
    color: rgba(255, 255, 255, 0.85);
    padding: 0;
    margin: 0;
  }
`;

export const Header = styled.h1`
  color: ${props => props.color || headerColor};
  font-size: ${props => props.size || '1.45em'};
  font-weight: normal;
  text-align: center;
  line-height: 0.9em;
  margin: 10px 0;
`;

export const Text = styled.p`
  color: ${props => props.color || textColor};
  font-size: 1em;
`;

export const TextNote = styled(Text)`
  color: #444;
  margin: 0;
  padding: 0;
`;

export const Label = styled.label`
  color: ${labelColor};
  font-size: 0.85em;
  text-transform: uppercase;
  font-weight: bold;
`;

export const LinkButton = styled.button`
  background: none;
  padding: none;
  border: 0;
  display: inline-block;
  color: ${linkColor};
  cursor: pointer;
  font-weight: bold;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.85);
`;

export const FormButton = styled.button`
  background-color: ${contentBgColor};
  padding: 6px 10px;
  border: 0;
  display: block;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-decoration: uppercase;
  font-weight: bold;
`;

//  !important here to fight against wordpress styles
export const Input = styled.input`
  border: 1px solid ${lightBorderColor} !important;
  border-radius: 3px !important;
  padding: 8px 12px;
  color: ${labelColor} !important;
  font-size: 1.1em !important;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  width: 100% !important;
  display: block;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

export const InputFormContainer = styled.div`
  width: 80%;
  border: 1px solid ${lightBorderColor};
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
`;

export const Spinner = () => {
  return (
    <>
      <Header size='1.25em' color='#fff'>
        One moment....
      </Header>
      <GridLoader color='#fff' />
    </>
  );
};
