const defaultResponseErrorSettings = {
  loginResponse: null,
  loginError: null,
  forgotResponse: null,
  forgotError: null,
  signupResponse: null,
  signupError: null,
  tokenVerifyResponse: null,
  tokenVerifyError: null
};

export const ageGateDefaultState = {
  ...defaultResponseErrorSettings,
  isRequesting: false,
  tokenValid: false,
  userVerified: false
  // cookieTokenIsValid: false,
  // cookieToken: ""
};

const getErrorText = data => {
  const dataWithErrorMessage = {
    ...data,
    message: data.message || 'unknown error'
  };
  if (data.missingRequiredData) {
    dataWithErrorMessage.message = 'Your submission is missing required data, please try again.';
  } else if (data.passwordMismatch) {
    dataWithErrorMessage.message = 'Your password did not match, please try again.';
  } else if (data.emailNotFound) {
    dataWithErrorMessage.message = 'Email submitted was not found.';
  } else if (data.emailNotValid) {
    dataWithErrorMessage.message = 'Email submitted was not a valid address.';
  } else if (data.emailExists) {
    dataWithErrorMessage.message =
      'Email submitted is in use, please check your data or use the forgot password form.';
  } else if (data.noToken) {
    dataWithErrorMessage.message = 'Token not found.';
  } else if (data.tokenValid === false) {
    dataWithErrorMessage.message = 'Invalid token, please login or signup.';
  }
  return dataWithErrorMessage;
};

const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
const LOGIN_ERROR = 'LOGIN_ERROR';
const FORGOT_REQUEST = 'FORGOT_REQUEST';
const FORGOT_RESPONSE = 'FORGOT_RESPONSE';
const FORGOT_ERROR = 'FORGOT_ERROR';
const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
const SIGNUP_RESPONSE = 'SIGNUP_RESPONSE';
const SIGNUP_ERROR = 'SIGNUP_ERROR';
const TOKEN_VERIFY_REQUEST = 'TOKEN_VERIFY_REQUEST';
const TOKEN_VERIFY_RESPONSE = 'TOKEN_VERIFY_RESPONSE';
const TOKEN_VERIFY_ERROR = 'TOKEN_VERIFY_ERROR';
export const TOKEN_VERIFY_LOCAL = 'TOKEN_VERIFY_LOCAL';
export const TOKEN_CLEAR = 'TOKEN_CLEAR';
export const ERROR_CLEAR = 'ERROR_CLEAR';

export const TOKEN_COOKIE_NAME = 'plc-ag';

export const getSubmitEvent = type => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'login':
      return {
        req: LOGIN_REQUEST,
        res: LOGIN_RESPONSE,
        err: LOGIN_ERROR
      };
    case 'forgot':
      return {
        req: FORGOT_REQUEST,
        res: FORGOT_RESPONSE,
        err: FORGOT_ERROR
      };
    case 'signup':
      return {
        req: SIGNUP_REQUEST,
        res: SIGNUP_RESPONSE,
        err: SIGNUP_ERROR
      };
    case 'token-verify':
      return {
        req: TOKEN_VERIFY_REQUEST,
        res: TOKEN_VERIFY_RESPONSE,
        err: TOKEN_VERIFY_ERROR
      };
  }
};

export const ageGateReducer = (state, action) => {
  console.log('ageGateReducer', action);
  // eslint-disable-next-line default-case
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isRequesting: true,
        ...defaultResponseErrorSettings
      };
    case LOGIN_RESPONSE:
      return {
        ...state,
        isRequesting: false,
        loginResponse: action.data,
        loginError: null,
        tokenValid: action.data.tokenValid,
        userVerified: action.data.userVerified,
        token: action.data.token
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isRequesting: false,
        loginResponse: null,
        loginError: getErrorText(action.data),
        tokenValid: false,
        userVerified: false,
        token: null
      };

    case FORGOT_REQUEST:
      return {
        ...state,
        isRequesting: true,
        ...defaultResponseErrorSettings
      };
    case FORGOT_RESPONSE:
      return {
        ...state,
        isRequesting: false,
        forgotResponse: action.data,
        forgotError: null
      };
    case FORGOT_ERROR:
      return {
        ...state,
        isRequesting: false,
        forgotResponse: null,
        forgotError: getErrorText(action.data),
        tokenValid: false,
        userVerified: false,
        token: null
      };

    case SIGNUP_REQUEST:
      return {
        ...state,
        isRequesting: true,
        ...defaultResponseErrorSettings
      };
    case SIGNUP_RESPONSE:
      return {
        ...state,
        isRequesting: false,
        signupResponse: action.data,
        signupError: null,
        tokenValid: action.data.tokenValid,
        userVerified: action.data.userVerified,
        token: action.data.token
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        isRequesting: false,
        signupResponse: null,
        signupError: getErrorText(action.data),
        tokenValid: false,
        userVerified: false,
        token: null
      };

    case TOKEN_VERIFY_REQUEST:
      return {
        ...state,
        isRequesting: true,
        ...defaultResponseErrorSettings
      };
    case TOKEN_VERIFY_RESPONSE:
      return {
        ...state,
        isRequesting: false,
        tokenVerifyResponse: action.data,
        tokenVerifyError: null,
        tokenValid: action.data.tokenValid,
        userVerified: action.data.userVerified
      };
    case TOKEN_VERIFY_LOCAL:
      return {
        ...state,
        isRequesting: false,
        tokenVerifyResponse: {},
        tokenVerifyError: null,
        tokenValid: true,
        userVerified: true
      };
    case TOKEN_VERIFY_ERROR:
      return {
        ...state,
        isRequesting: false,
        tokenVerifyResponse: null,
        tokenVerifyError: getErrorText(action.data),
        tokenValid: false,
        userVerified: false
      };
    case TOKEN_CLEAR:
      return {
        ...state,
        isRequesting: false,
        ...defaultResponseErrorSettings,
        token: null,
        tokenValid: false,
        userVerified: false
      };
    case ERROR_CLEAR:
      return {
        ...state,
        forgotError: null,
        loginError: null,
        signupError: null,
        tokenVerifyError: null
      };
    default:
      return state;
  }
};
