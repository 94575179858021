import React from "react";
import {
    Header,
    Text,
    FormButton,
    Input,
    InputFormContainer,
    LinkButton,
    Spinner
} from "./StyledParts";

const LoginForm = (props) => {
    const {
        setContentType,
        handleFormInput,
        isRequesting,
        formHandler,
        formContent
    } = props;

    const handleClick = (event) => {
        event.preventDefault();
        if (!isRequesting) {
            setContentType("signup");
        }
    };

    const handleClickForgot = (event) => {
        event.preventDefault();
        if (!isRequesting) {
            setContentType("forgot");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("submit form!");
        formHandler("login");
    };

    return (
        <>
            <Header>
                Are you already Age Verified? Login here to start shopping!
            </Header>
            {isRequesting && <Spinner />}
            {!isRequesting && (
                <InputFormContainer>
                    <form onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            name="login_email"
                            placeholder="Enter email"
                            value={formContent.login_email || ""}
                            onChange={handleFormInput}
                        />
                        <Input
                            type="password"
                            name="login_password"
                            placeholder="Enter password"
                            value={formContent.login_password || ""}
                            onChange={handleFormInput}
                        />
                        <FormButton type="submit">Login</FormButton>
                    </form>
                </InputFormContainer>
            )}
            <Text style={{ textAlign: "center" }} color="#fff">
                Forgot your password?{" "}
                <LinkButton onClick={handleClickForgot} disabled={isRequesting}>
                    Reset it
                </LinkButton>
                <br />
                Not a member?{" "}
                <LinkButton onClick={handleClick} disabled={isRequesting}>
                    Sign up
                </LinkButton>
            </Text>
        </>
    );
};

export default LoginForm;
