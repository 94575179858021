import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, Select } from '@chakra-ui/react';
import {
  Header,
  Text,
  FormButton,
  Input,
  InputFormContainer,
  LinkButton,
  Spinner
} from './StyledParts';
import locationCodes from 'constants/location-codes.json';

const FlexDiv = styled.div`
  display: flex;
  & > div {
    width: 50%;
  }
  & > div:first-child {
    padding-right: 8px;
  }
  & > div:last-child {
    padding-left: 8px;
  }
`;

const locationOptions = locationCodes
  .sort((a, b) => {
    const ap = a.locationName || 0;
    const bp = b.locationName || 0;
    return ap > bp ? 1 : bp > ap ? -1 : 0;
  })
  .map((loc, idx) => {
    return (
      <option value={loc.code} key={idx}>
        {loc.locationName}
        {loc.metroName ? ` (${loc.metroName})` : ''}
      </option>
    );
  });

const SignupForm = props => {
  const { setContentType, handleFormInput, isRequesting, formHandler, formContent } = props;

  const handleClick = event => {
    event.preventDefault();
    if (!isRequesting) {
      setContentType('login');
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log('submit form!');
    formHandler('signup');
  };

  return (
    <>
      <Header>
        To shop with Plantlife, you must be at least 18 years of age. To confirm your age, please
        register below.
      </Header>
      {isRequesting && <Spinner />}
      {!isRequesting && (
        <InputFormContainer>
          <form onSubmit={handleSubmit}>
            <FlexDiv>
              <div>
                <Input
                  type='text'
                  name='signup_firstname'
                  placeholder='First name'
                  value={formContent.signup_firstname || ''}
                  onChange={handleFormInput}
                />
              </div>
              <div>
                <Input
                  type='text'
                  name='signup_lastname'
                  placeholder='Last name'
                  value={formContent.signup_lastname || ''}
                  onChange={handleFormInput}
                />
              </div>
            </FlexDiv>
            <Input
              type='text'
              name='signup_email'
              placeholder='Email'
              value={formContent.signup_email || ''}
              onChange={handleFormInput}
            />
            <Input
              type='text'
              name='signup_phone'
              placeholder='Phone'
              value={formContent.signup_phone || ''}
              onChange={handleFormInput}
            />
            <Input
              type='password'
              name='signup_password'
              placeholder='Password'
              value={formContent.signup_password || ''}
              onChange={handleFormInput}
            />
            <Select
              name='signup_preferredLocation'
              mb='10px'
              onChange={handleFormInput}
              placeholder='Select your preferred store'
              value={formContent.signup_preferredLocation || ''}
            >
              {locationOptions}
            </Select>
            <FormButton type='submit'>Signup</FormButton>
          </form>
        </InputFormContainer>
      )}
      <Text>
        Already a member? <LinkButton onClick={handleClick}>Login</LinkButton>
      </Text>
    </>
  );
};

export default SignupForm;
