import React, { useMemo, useState, useCallback } from 'react';
// import { Heading, Portal, Text, VStack } from "@chakra-ui/react";
// import styled from "@emotion/styled";
import useAgeGate from '../hooks/useAgeGate';
import ReactPortal from './ReactPortal';
import { FullScreenBlocker, ContentContainer } from './StyledParts';
import LoginForm from './LoginForm';
import ForgotForm from './ForgotForm';
import SignupForm from './SignupForm';
import ErrorMessageDisplay from './ErrorMessageDisplay';
import PendingAccountNotice from './PendingAccountNotice';

const SHOW_DEV_NOTES = false;
const DevNotes = props => {
  const { token, userVerified, tokenValid, clearToken, color = '#000' } = props;
  return (
    <p style={{ color }}>
      token: {token || 'no token found'}
      <br />
      userVerified: {userVerified ? 'yes' : 'no'}
      <br />
      tokenValid: {tokenValid ? 'yes' : 'no'}
      <br />
      <button onClick={clearToken}>Clear Token</button>
    </p>
  );
};

const AgeGate = () => {
  const {
    isRequesting,
    ageGateError,
    ageGateResponse,
    token,
    errorClear,
    clearToken,
    handleFormInput,
    formHandler,
    formContent,
    userVerified,
    tokenValid
  } = useAgeGate();

  const [contentType, setContentType] = useState('login');
  const updateContentType = useCallback(
    type => {
      setContentType(type);
      errorClear();
    },
    [errorClear]
  );

  const ShowContent = useMemo(() => {
    // if (window?.location?.search) {
    //   const urlParams = window?.URLSearchParams && new URLSearchParams(window?.location?.search);
    //   const showStep = urlParams?.get('showStep');
    //   console.warn('showStep', showStep);
    //   switch (showStep) {
    //     case 'pending':
    //       return PendingAccountNotice;
    //   }
    // }
    if (tokenValid && !userVerified) {
      return PendingAccountNotice;
    }
    // eslint-disable-next-line default-case
    switch (contentType) {
      case 'login':
        return LoginForm;
      case 'forgot':
        return ForgotForm;
      case 'signup':
        return SignupForm;
      default:
        throw new Error(`bad content type!!!:: ${contentType}`);
    }
  }, [contentType, tokenValid, userVerified]);

  if (userVerified) {
    console.log('AGEGATE user is verified, do nothing');
    if (SHOW_DEV_NOTES) {
      return (
        <DevNotes
          token={token}
          userVerified={userVerified}
          tokenValid={tokenValid}
          clearToken={clearToken}
        />
      );
    }
    return null;
  }

  return (
    <ReactPortal wrapperId='plc-age-gate-portal'>
      <FullScreenBlocker>
        <ContentContainer>
          <img
            alt='Plantlife Cannabis Logo'
            src='https://plantlifecannabisuni.com/public/gfx/plantifelogo-white.png'
            style={{ display: 'block', margin: '0 auto' }}
          />
          <ShowContent
            isRequesting={isRequesting}
            setContentType={updateContentType}
            handleFormInput={handleFormInput}
            formHandler={formHandler}
            formContent={formContent}
            response={ageGateResponse}
          />
          <ErrorMessageDisplay error={ageGateError} />
        </ContentContainer>
        {SHOW_DEV_NOTES && (
          <DevNotes
            token={token}
            userVerified={userVerified}
            tokenValid={tokenValid}
            clearToken={clearToken}
            color='yellow'
          />
        )}
      </FullScreenBlocker>
    </ReactPortal>
  );
};

export default AgeGate;
