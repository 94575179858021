import React from "react";
import { Header, Text, ErrorContainer } from "./StyledParts";

const ErrorMessageDisplay = (props) => {
    const { error } = props;
    if (!error) {
        return null;
    }
    return (
        <ErrorContainer>
            <Header>Sorry there was an error</Header>
            <Text>{error?.message || JSON.stringify(error)}</Text>
        </ErrorContainer>
    );
};

export default ErrorMessageDisplay;
