import { useState, useReducer, useEffect, useRef, useCallback } from 'react';
import {
  ageGateDefaultState,
  ageGateReducer,
  getSubmitEvent,
  ERROR_CLEAR,
  TOKEN_COOKIE_NAME,
  TOKEN_VERIFY_LOCAL,
  TOKEN_CLEAR
} from './ageGateReducer';
import { useCookies } from 'react-cookie';

const HOST_DOMAIN =
  document?.location?.host?.indexOf('localhost') > -1
    ? 'http://localhost:34800'
    : 'https://plc-age-gate-takeover.dynamicintegratedtechnology.com';

// remember the user's status this long, if verified
const _90DAYS = 86400 * 90;

const checkFormByType = (type, params) => {
  switch (type) {
    case 'signup':
      if (
        !params?.signup_firstname ||
        !params?.signup_lastname ||
        !params?.signup_email ||
        !params?.signup_password ||
        !params?.signup_preferredLocation ||
        !params?.signup_phone
      ) {
        return false;
      }
      break;
    case 'login':
      if (!params?.login_email || !params?.login_password) {
        return false;
      }
      break;
    case 'forgot':
      if (!params?.forgot_email) {
        return false;
      }
      break;
  }
  return true;
};

const useAgeGate = () => {
  const tokenVerifiedOnce = useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_COOKIE_NAME]);
  const [formContent, setFormContent] = useState({});
  const [ageGateState, ageGateDispatch] = useReducer(ageGateReducer, {
    ...ageGateDefaultState
  });

  const handleFormInput = event => {
    const {
      target: { name, value }
    } = event;
    const myContent = { ...formContent, [name]: value };
    setFormContent(myContent);
  };

  const apiCall = (type, DISPATCH_EVENT, formParams) => {
    ageGateDispatch({ type: DISPATCH_EVENT.req });
    return fetch(`${HOST_DOMAIN}/api/${type}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formParams)
    }).then(async response => {
      const data = await response.json();
      console.log('json() done...', data, response.status);
      return {
        data,
        status: response.status
      };
    });
  };

  const setCookieToken = useCallback(
    (token, userVerified) => {
      const options = {
        maxAge: _90DAYS,
        path: '/',
        sameSite: 'none',
        domain: 'plc-age-gate-takeover.dynamicintegratedtechnology.com',
        secure: !!(HOST_DOMAIN.indexOf('localhost') > -1)
      };
      if (token) {
        const tokenWithFlag = token + '|' + (userVerified ? '1' : '0');
        if (localStorage) {
          localStorage.setItem(TOKEN_COOKIE_NAME, tokenWithFlag);
        } else {
          setCookie(TOKEN_COOKIE_NAME, tokenWithFlag, options);
        }
      } else {
        if (localStorage) {
          localStorage.removeItem(TOKEN_COOKIE_NAME);
        } else {
          removeCookie(TOKEN_COOKIE_NAME, options);
        }
      }
    },
    [removeCookie, setCookie]
  );

  // try to verify an existing token
  useEffect(() => {
    const myToken = localStorage
      ? localStorage.getItem(TOKEN_COOKIE_NAME) || ''
      : cookies[TOKEN_COOKIE_NAME] || '';
    if (myToken && !tokenVerifiedOnce.current) {
      tokenVerifiedOnce.current = true;
      const [token, isVerified] = myToken?.split('|') || [];
      if (token && isVerified === '1') {
        // if token is valid and verified by cookie, set appropriate local flags, skip verification
        // 1. update time flag
        setCookieToken(token, true);
        // 2. update reducer
        return ageGateDispatch({
          type: TOKEN_VERIFY_LOCAL
        });
      }
      const DISPATCH_EVENT = getSubmitEvent('token-verify');
      apiCall('token-verify', DISPATCH_EVENT, {
        token
      })
        .then(rObj => {
          console.log('[token verify] got a server response', rObj);
          const { status, data: response } = rObj;
          if (status <= 299) {
            setCookieToken(token, response.userVerified);
            ageGateDispatch({
              type: DISPATCH_EVENT.res,
              data: response
            });
          } else {
            setCookieToken('');
            ageGateDispatch({
              type: DISPATCH_EVENT.err,
              data: response
            });
          }
        })
        .catch(error => {
          console.error('api error response!!', error);
          ageGateDispatch({
            type: DISPATCH_EVENT.err,
            data: error
          });
        });
    }
  }, [cookies, setCookieToken]);

  const formHandler = type => {
    const DISPATCH_EVENT = getSubmitEvent(type);
    if (!DISPATCH_EVENT) {
      console.error('cannot find a DISPATCH_EVENT for type=' + type);
      return;
    }
    const formParams = {};
    Object.keys(formContent).forEach(k => {
      if (k.indexOf(type) === 0) {
        formParams[k] = formContent[k];
      }
    });

    const formIsValid = checkFormByType(type, formParams);
    if (!formIsValid) {
      return ageGateDispatch({
        type: DISPATCH_EVENT.err,
        data: { missingRequiredData: true }
      });
    }

    apiCall(type, DISPATCH_EVENT, formParams)
      .then(rObj => {
        const { status, data: response } = rObj;
        if (status <= 299) {
          const { token, tokenValid, userVerified } = response;
          if (token && tokenValid) {
            tokenVerifiedOnce.current = true;
            setCookieToken(token, userVerified);
          }
          ageGateDispatch({
            type: DISPATCH_EVENT.res,
            data: response
          });
        } else {
          setCookieToken('');
          ageGateDispatch({
            type: DISPATCH_EVENT.err,
            data: response
          });
        }
      })
      .catch(error => {
        ageGateDispatch({ type: DISPATCH_EVENT.err, data: error });
      });
  };

  const clearToken = () => {
    setCookieToken('');
    ageGateDispatch({
      type: TOKEN_CLEAR
    });
  };

  const errorClear = () => {
    ageGateDispatch({
      type: ERROR_CLEAR
    });
  };

  return {
    isVerified: ageGateState.isVerified,
    isRequesting: ageGateState.isRequesting,
    token: localStorage
      ? localStorage.getItem(TOKEN_COOKIE_NAME) || ''
      : cookies[TOKEN_COOKIE_NAME] || '',
    tokenValid: ageGateState.tokenValid,
    userVerified: ageGateState.userVerified,
    ageGateResponse:
      ageGateState.loginResponse ||
      ageGateState.signupResponse ||
      ageGateState.forgotResponse ||
      ageGateState.tokenVerifyResponse,
    ageGateError:
      ageGateState.loginError ||
      ageGateState.signupError ||
      ageGateState.forgotError ||
      ageGateState.tokenVerifyError,

    errorClear,
    clearToken,
    formHandler,
    handleFormInput,
    formContent
  };
};

export default useAgeGate;
